import apiUrl from '../apiUrl';
import { AppBar, Drawer, IconButton, InputBase, MenuItem, Paper, SwipeableDrawer, TextField, Toolbar, Typography, alpha } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuIcon from "@mui/icons-material/Menu";
import React, { Component, useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FlagFI from '../assets/icons/flag-fi.png';
import FlagEN from '../assets/icons/flag-uk.png';
import snackersLogoWhite from '../assets/icons/snackers-logo-white.png'
import snackersLogoBlack from '../assets/icons/snackers-logo-black.png'
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../styles/css/App.css'
import SearchIcon from '@mui/icons-material/Search';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import styled from '@emotion/styled';

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     margin: '0 1rem 0 1rem',
//     maxWidth: '300px',
//     [theme.breakpoints.up('sm')]: {
//         maxWidth: '300px',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     // @ts-ignore
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     width: '100%',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         // @ts-ignore
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         [theme.breakpoints.up('sm')]: {
//             width: '20ch',
//             '&:focus': {
//                 width: '20ch',
//             },
//         },
//     },
// }));

const NavigationBar = ({ navigationBarData }) => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [accountMenu, setAccountMenu] = React.useState(null);

    let searchTerm = '';
    // let searchResults;

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAccountMenuClose = () => {
        setAccountMenu(null);
    };

    const handleLogoClick = () => {
        if (location.pathname === '/snacks') {
            window.location.reload(); // Refresh the page if already on /snacks
        } else {
            navigate('/snacks'); // Navigate to /snacks if not already there
        }
    };

    const checkLoginStatus = () => {
        const token = sessionStorage.getItem('token');
        return !!token; // Returns true if token exists, otherwise false
    };

    // const [username, setUsername] = useState(null);
    // const getUsernameFromToken = () => {
    //     const token = sessionStorage.getItem('token');
    //     if (token) {
    //         const decodedToken = JSON.parse(atob(token.split('.')[1]));
    //         setUsername(decodedToken.username);
    //     } else {
    //         setUsername(null);
    //     }
    // }

    const Logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.setItem('logoutSuccess', 'true');
        if ('homeComponent' in navigationBarData) {
            window.location.reload();
        }
        navigate('/')
    };

    // Comment out this function for now since there is no functionality for the search function. 07.04.2024.
    // Function to grab user input from searchbox

    // let timeoutId;
    // const handleChange = (event) => {
    //     clearTimeout(timeoutId); // Clear any existing timeout
    //     searchTerm = event.target.value;

    //     if (searchTerm.length > 1) {
    //         // Set a new timeout to trigger handleSearch after 1 second
    //         timeoutId = setTimeout(handleSearch, 1000);
    //     }
    // };

    // const [searchResults, setSearchResults] = useState([]);

    // const handleSearch = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}/searchSnacks?query=${searchTerm}`);
    //         if (response.ok) {
    //             const data = await response.json();
    //             setSearchResults(data);
    //             // Process the fetched data as needed
    //             console.log(searchResults);
    //         } else {
    //             console.error('Failed to fetch data:', response.statusText);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error.message);
    //     }
    // };

    const leftIcon = () => {
        if ('loginComponent' in navigationBarData) {
            return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => navigate("/")}>
                <ArrowBackIcon />
            </IconButton>
        } else if ('signupComponent' in navigationBarData) {
            return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => navigate("/login")}>
                <ArrowBackIcon />
            </IconButton>
        } else if ('forgotPasswordComponent' in navigationBarData) {
            return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => navigate("/login")}>
                <ArrowBackIcon />
            </IconButton>
        } else {
            return <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleMenu}>
                <MenuIcon />
            </IconButton>
        }
    }

    const middleText = () => {
        if ('loginComponent' in navigationBarData) {
            return <Typography variant="h6" className='navbar-middletext'>{t('login_header')}</Typography>
        } else if ('signupComponent' in navigationBarData) {
            return <Typography variant="h6" className='navbar-middletext'>{t('create_new_user_header')}</Typography>
        } else if ('forgotPasswordComponent' in navigationBarData) {
            return <Typography variant="h6" className='navbar-middletext'>{t('forgot_password_header')}</Typography>
        } else if ('userSettingsComponent' in navigationBarData) {
            return <Typography variant="h6" className='navbar-middletext'>{t('user_settings_header')}</Typography>
        } else if ('profileComponent' in navigationBarData) {
            return <Typography variant="h6" className='navbar-middletext'>{t('profile_header')}</Typography>
        } else if ('myReviewsComponent' in navigationBarData) {
            return <Typography variant="h6" className='navbar-middletext'>{t('my_snacks_header')}</Typography>
        } else {
            return <Box
                sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'flex' },
                    alignItems: 'center',
                }}
            >
                <Link to="/snacks" style={{ textDecoration: 'none' }}>
                    <Box
                        component="img"
                        src={snackersLogoWhite}
                        alt="Snackers logo"
                        sx={{
                            maxHeight: '40px',
                            transition: 'filter 0.05s ease',
                            '&:hover': {
                                filter: 'grayscale(100%) invert(1%)',
                            },
                        }}
                        onClick={handleLogoClick} // Add onClick handler
                    />
                </Link>
            </Box>

            // Comment out searchbox for now. 07.04.2024.
            // return <><div style={{ position: 'relative' }}><Search>
            //     <SearchIconWrapper>
            //         <SearchIcon />
            //     </SearchIconWrapper>
            //     <StyledInputBase
            //         placeholder={t('search_placeholder')}
            //         inputProps={{ 'aria-label': 'search' }}
            //         onChange={handleChange}
            //     />
            // </Search>
            // {searchResults.length > 0 && (
            //     <Paper elevation={3} style={{
            //         position: 'absolute',
            //         top: '100%',
            //         left: 0,
            //         zIndex: 1000, // Adjust the z-index value as needed
            //         marginTop: '16px',
            //         padding: '16px',
            //     }}>
            //         {searchResults.map((result) => (
            //             <Typography key={result.id} variant="body1">
            //                 {/* Render search result item */}
            //                 {result.name.fi}
            //             </Typography>
            //         ))}
            //     </Paper>
            // )}
            // </div>
            // </>
        }
    }

    return (
        <AppBar position="static" style={{ display: 'flex', flexDirection: 'unset' }}>
            <Toolbar className='navbar'>
                <Box sx={{
                    maxWidth: { xs: '200px' },
                    width: { md: '200px' },
                    display: 'flex',

                }}>
                    {leftIcon()}
                    {/* <Box
                        sx={{
                            display: { xs: 'flex', sm: 'flex', md: 'flex' },
                            alignItems: 'center',
                        }}
                    >
                        <img src={snackersLogo} alt='Snackers logo' style={{ maxHeight: '40px' }} />
                        <Typography variant='h5' sx={{ height: '20px' }}>Snackers</Typography>
                    </Box> */}
                </Box>

                {middleText()}

                <Box
                    sx={{
                        width: { md: '200px' },
                        maxWidth: { xs: '200px' },
                        display: 'flex',
                        justifyContent: 'flex-end',

                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="accountMenu"
                        edge="end"
                        sx={{ padding: '12px' }}
                        onClick={handleAccountMenu}
                    >
                        <AccountCircle />
                    </IconButton>
                </Box>


                <SwipeableDrawer
                    anchor="left"
                    disableSwipeToOpen
                    open={Boolean(anchorEl)}
                    onOpen={handleMenuClose}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    PaperProps={{ sx: { width: 300 } }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '2rem 0 2rem 1rem' }}>
                        <img src={snackersLogoBlack} alt='Snackers logo' style={{ maxHeight: '60px' }} />

                    </Box>
                    <MenuItem component={Link} to="/" >
                        <HomeOutlinedIcon sx={{ marginRight: '0.5rem', marginLeft: '1rem' }} />
                        {t('home_button')}
                    </MenuItem>
                    <MenuItem component={Link} to="/snacks">
                        <AppsOutlinedIcon sx={{ marginRight: '0.5rem', marginLeft: '1rem' }} />
                        {t('browse_button')}
                    </MenuItem>
                    <MenuItem component={Link} to="/about">
                        <InfoOutlinedIcon sx={{ marginRight: '0.5rem', marginLeft: '1rem' }} />
                        {t('about_button')}
                    </MenuItem>
                </SwipeableDrawer>

                <SwipeableDrawer
                    anchor="right"
                    disableSwipeToOpen
                    open={Boolean(accountMenu)}
                    onOpen={handleAccountMenuClose}
                    onClose={handleAccountMenuClose}
                    onClick={handleAccountMenuClose}
                    PaperProps={{ sx: { width: 300 } }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '2rem 0 2rem 1rem' }}>
                        <img src={snackersLogoBlack} alt='Snackers logo' style={{ maxHeight: '60px' }} />
                    </Box>

                    {!checkLoginStatus() && <MenuItem component={Link} to="/login">
                        <LoginOutlinedIcon sx={{ marginRight: '1rem', marginLeft: '0.5rem' }} />
                        {t('login_button')}
                    </MenuItem>}

                    {checkLoginStatus() &&
                        <>
                            <MenuItem component={Link} to="/mysnacks">
                                <ListAltOutlinedIcon sx={{ marginRight: '1rem', marginLeft: '0.5rem' }} />
                                {t('my_snacks_button')}
                            </MenuItem>
                            {/* <MenuItem component={Link} to="/profile">
                                <AccountCircleOutlinedIcon sx={{ marginRight: '1rem', marginLeft: '0.5rem' }} />
                                {t('user_profile_button')}
                            </MenuItem> */}
                            <MenuItem component={Link} to="/usersettings">
                                <ManageAccountsOutlinedIcon sx={{ marginRight: '1rem', marginLeft: '0.5rem' }} />
                                {t('user_settings_button')}
                            </MenuItem>
                        </>
                    }

                    <MenuItem onClick={() => changeLanguage('fi')}>
                        <img src={FlagFI} alt='Flag of Finland' style={{ marginRight: '1.3rem', marginLeft: '0.7rem' }} />
                        Suomeksi
                    </MenuItem>

                    <MenuItem onClick={() => changeLanguage('en')}>
                        <img src={FlagEN} alt='Flag of the UK' style={{ marginRight: '1.3rem', marginLeft: '0.7rem' }} />
                        In English
                    </MenuItem>

                    {checkLoginStatus() && <MenuItem onClick={() => Logout()}>
                        <LogoutOutlinedIcon sx={{ marginRight: '1rem', marginLeft: '0.5rem' }} />
                        {t('log_out_button')}
                    </MenuItem>}
                </SwipeableDrawer>
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
