// @ts-nocheck
import apiUrl from '../apiUrl';
import { alpha } from '@mui/material';
import Fab from '@mui/material/Fab';
import TuneIcon from '@mui/icons-material/Tune';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import NavigationBar from './NavigationBar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Snacks = () => {
    const { t } = useTranslation();
    const dataToNavigationbar = {
        snacksComponent: '',
    }

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSnacks, setSelectedSnacks] = useState({ beverages: true, salty: true, sweets: true });
    const [snacksData, setSnacksData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSnacks = async () => {
            try {
                const response = await fetch(`${apiUrl}/snacks`);
                if (response.ok) {
                    const data = await response.json();
                    setSnacksData(data);
                    setLoading(false);
                } else {
                    throw new Error('Network response was not ok.');
                }
            } catch (error) {
                console.error('There was a problem fetching the data:', error);
                setLoading(false);
                // Handle errors, e.g., set error state, show a message, etc.
            }
        };
        fetchSnacks(); // Invoke the function to fetch data when component mounts
    }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleCheckboxChange = (event) => {
        setSelectedSnacks({
            ...selectedSnacks,
            [event.target.name]: event.target.checked,
        });
    };

    // Filter snacks based on selected checkboxes
    const filteredSnacks = snacksData.filter(snack => {
        if (selectedSnacks.beverages && snack.snackType === 'beverage') {
            return true;
        }
        if (selectedSnacks.salty && snack.snackType === 'salty') {
            return true;
        }
        if (selectedSnacks.sweets && snack.snackType === 'sweet') {
            return true;
        }
        return false;
    });

    return (
        <div>
            <NavigationBar navigationBarData={dataToNavigationbar} />
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <div className='main-content'>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <Grid
                                container
                                spacing={2}
                            >
                                {filteredSnacks
                                    // Date objects giving error "The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.ts(2362)".
                                    // Regardless the app *IS WORKING* at least in 17.12.2023 so ignore for now.
                                    // There shouldn't be Typescript type checking in this project since it's a Javascript project. Under investigation.
                                    .sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated))
                                    .map((snack, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4}>
                                            <Card>
                                                <CardContent sx={{ padding: 0 }}>
                                                    {snack.image &&
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ height: '300px' }}
                                                            image={snack.image} // Add image URL if available
                                                            title="Snack Image"
                                                        />
                                                    }
                                                    <Box sx={{ padding: '1rem 1rem 0 1rem', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                        <Typography fontSize={'0.7rem'} color={alpha('#000000', 0.5)} component="div">
                                                            {t('timestamp_text')} {new Date(snack.timeCreated).toLocaleDateString('en-GB')}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: "1",
                                                                WebkitBoxOrient: "vertical",
                                                            }}
                                                        >
                                                            {snack.productBrand}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: "1",
                                                                WebkitBoxOrient: "vertical",
                                                                lineHeight: 1.2,
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {snack.productName}
                                                        </Typography>
                                                        {/* Spacer to push content to the bottom */}
                                                        <Box sx={{ flexGrow: 1 }} />
                                                        <Box>
                                                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                                <Typography sx={{ fontSize: '0.8em', marginRight: '0.5em' }} color={alpha('#000000', 0.5)} component="div">
                                                                    {t('average_rating_info_text')}
                                                                </Typography>
                                                                <Typography>
                                                                    {snack.averageRating.toFixed(1)}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Rating
                                                                    name={`rating-${index}`}
                                                                    value={snack.averageRating}
                                                                    precision={0.5}
                                                                    max={5}
                                                                    size='medium'
                                                                    readOnly
                                                                />
                                                                <Typography sx={{ flexGrow: 1, alignContent: 'center', fontSize: '0.8em', marginLeft: '0.3em' }} color={alpha('#000000', 0.5)} component="div">
                                                                    ({snack.reviewsAmount})
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                                <CardActions sx={{ padding: '1rem 1rem 1rem 1rem' }}>
                                                    <Button size="small" variant='outlined' component={Link} to={`/snackpage/${snack.id}`}>
                                                        {t('rating_card_open_button')}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                            </Grid>
                            <div style={{ marginBottom: '96px' }} />
                            <Fab onClick={handleDialogOpen} color="primary" aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                                <TuneIcon />
                            </Fab>
                        </div>

                    )}
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle>{t('filter_results_modal_header')}</DialogTitle>
                        <DialogContent>
                            <FormControlLabel
                                control={<Checkbox checked={selectedSnacks.beverages} onChange={handleCheckboxChange} name="beverages" />}
                                label={t('filter_results_modal_checkbox_beverages')}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedSnacks.salty} onChange={handleCheckboxChange} name="salty" />}
                                label={t('filter_results_modal_checkbox_salty')}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedSnacks.sweets} onChange={handleCheckboxChange} name="sweets" />}
                                label={t('filter_results_modal_checkbox_sweets')}
                            />
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Snacks;
