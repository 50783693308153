import apiUrl from '../apiUrl';
import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

import NavigationBar from './NavigationBar';

export default function UserSettings() {
    const { t } = useTranslation();

    const dataToNavigationbar = {
        userSettingsComponent: '',
    }

    const [changePasswordMessageVisible, setChangePasswordMessageVisible] = useState(false);
    const formRef = useRef(null);

    const handlePasswordChangeSuccess = () => {
        const changePasswordSuccess = sessionStorage.getItem('changePasswordSuccess');
        if (changePasswordSuccess === 'true') {
            setChangePasswordMessageVisible(true);
            sessionStorage.removeItem('changePasswordSuccess'); // Remove the flag after displaying the message
        }

        const handleClick = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                setChangePasswordMessageVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    };

    const [formValues, setFormValues] = useState({ newPassword: '', confirmNewPassword: '' });
    const [formErrors, setFormErrors] = useState({ newPassword: '', confirmNewPassword: '' });

    const validateForm = () => {
        const errors = {
            newPassword: '',
            confirmNewPassword: '',
        };

        if (formValues.newPassword.length < 8) {
            errors.newPassword = t('password_error');
        }

        if (formValues.confirmNewPassword !== formValues.newPassword) {
            errors.confirmNewPassword = t('password_again_error');
        }

        setFormErrors(errors);

        return Object.values(errors).every((error) => error === '');
    };

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateForm()) {
            const data = new FormData(event.currentTarget);
            const token = sessionStorage.getItem('token');

            if (!token) {
                console.error('Token not found');
                // Handle the case where the token is missing or invalid
                navigate('/login')
                return;
            }
            fetch(`${apiUrl}/changepassword`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
                body: data,
            })
                .then((response) => {
                    if (response.ok) {
                        sessionStorage.setItem('changePasswordSuccess', 'true'); // Set a flag for successful signup
                        handlePasswordChangeSuccess()
                    } else {
                        console.error('Error sending form data');
                    }
                })
                .catch((error) => {
                    console.error('Error sending form data:', error);
                });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));

        setChangePasswordMessageVisible(false);
    };

    return (
        <div>
            <NavigationBar navigationBarData={dataToNavigationbar} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography margin={'1rem 0 0'}>
                        {t('change_password_text')}
                    </Typography>
                    <Box component="form" ref={formRef} onSubmit={handleSubmit} noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label={t('change_password_password_placeholder')}
                            type="password"
                            autoComplete="current-password"
                            value={formValues.newPassword}
                            onChange={handleInputChange}
                            error={!!formErrors.newPassword}
                            helperText={formErrors.newPassword}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmNewPassword"
                            label={t('password_again_placeholder')}
                            type="password"
                            autoComplete="current-password"
                            value={formValues.confirmNewPassword}
                            onChange={handleInputChange}
                            error={!!formErrors.confirmNewPassword}
                            helperText={formErrors.confirmNewPassword}
                        />
                        <Collapse in={changePasswordMessageVisible}>
                            <Alert variant='filled' severity="success" sx={{ width: '100%', marginTop: '1rem' }} onClose={() => setChangePasswordMessageVisible(false)}>
                                {t('successful_change_password_alert')}
                            </Alert>
                        </Collapse>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t('change_password_submit_button')}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}