// @ts-nocheck
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import fontTheme from './styles/font';
import { ThemeProvider } from '@mui/material';
import React from 'react';

// Component imports
import Home from './components/Home';
import About from './components/About';
import NotFound from './components/NotFound';
import Login from './components/Login';
import Signup from './components/Signup';
// import ForgotPassword from './components/ForgotPassword';
import UserSettings from './components/UserSettings';
import Snacks from './components/Snacks';
import Profile from './components/Profile';
import SnackPage from './components/SnackPage';
import EmailConfirmation from './components/EmailConfirmation';
import MySnacks from './components/MySnacks';

function App() {
  return (
    <ThemeProvider theme={fontTheme}>
      <Router>
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/snacks" element={<Snacks />} />
            <Route path="/snackpage/:snackId" element={<SnackPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {/* <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
            <Route path="/usersettings" element={<UserSettings />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path='/mysnacks' element={<MySnacks />} />
            {/* <Route path="/emailconfirmation/:verificationToken" element={<EmailConfirmation />} /> */}
            <Route element={<NotFound />} />
          </Routes>
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
