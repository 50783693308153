// @ts-nocheck
import apiUrl from '../apiUrl';
import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import NavigationBar from './NavigationBar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Slider from '@mui/material/Slider';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Fab from '@mui/material/Fab';
import TuneIcon from '@mui/icons-material/Tune';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { alpha } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import Snackbar from '@mui/material/Snackbar';

import { green } from '@mui/material/colors';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import Grid from '@mui/material/Grid';

const MySnacks = () => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const dataToNavigationbar = {
        myReviewsComponent: '',
    }
    const fileInputRef = useRef(null);
    const initialFormValues = { snackType: '', productBrand: '', productName: '', rating: 3, image: null, comment: '' };

    const [snacksData, setSnacksData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openFabDialog, setOpenFabDialog] = useState(false);
    const [formValues, setFormValues] = useState({ snackType: '', productBrand: '', productName: '', rating: 3, image: null, comment: '' });
    const [formErrors, setFormErrors] = useState({ snackType: '', productBrand: '', productName: '', image: '' });
    const [selectedSnacks, setSelectedSnacks] = useState({ beverages: true, salty: true, sweets: true });
    const [loading, setLoading] = useState(true);
    const [newItemLoading, setNewItemLoading] = useState(false);
    const [snacksNotFound, setSnacksNotFound] = useState(false);
    const [formSubmitFailure, setFormSubmitFailure] = useState(false);
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

    // Comment out delete functionality for now. 16.04.2023.
    // const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // Comment out delete functionality for now. 16.04.2023.
    // const [cardInformation, setCardInformation] = useState({});

    const fetchSnacks = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const response = await fetch(`${apiUrl}/mysnacks`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                }
            })
            if (response.status === 204) {
                setSnacksNotFound(true);
                setLoading(false);
            } else if (response.ok) {
                const data = await response.json();
                setSnacksData(data);
                setLoading(false);
                setSnacksNotFound(false);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('There was a problem fetching the data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSnacks(); // Invoke the function to fetch data when component mounts
    }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

    const handleDialogOpen = () => {
        setOpenDialog(true);
        formValues.image = null;
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setFormValues(initialFormValues);
        setFormErrors('');
        // Clear the selected image by resetting the input value
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFabDialogOpen = () => {
        setOpenFabDialog(true);
    };

    const handleFabDialogClose = () => {
        setOpenFabDialog(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };

    const handleImageInputChange = (event) => {
        const { name, files } = event.target;
        if (files.length > 0) {
            const file = files[0];

            // Check if the selected file is an image
            if (file.type.startsWith('image/')) {
                new Compressor(file, {
                    quality: 0.8, // Adjust the quality as needed
                    maxWidth: 800, // Adjust the maximum width as needed
                    success(result) {
                        setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            [name]: result, // Set the compressed image as the value
                        }));
                    },
                    error(err) {
                        console.error('Image compression error:', err.message);
                    },
                });
            } else {
                console.error('Selected file is not an image.');
            }
        }
    };

    const handleCheckboxChange = (event) => {
        setSelectedSnacks({
            ...selectedSnacks,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSliderChange = (event, newValue) => {
        setFormValues({ ...formValues, rating: newValue });
    };

    // Filter snacks based on selected checkboxes
    const filteredSnacks = snacksData.filter(snack => {
        if (selectedSnacks.beverages && snack.snackType === 'beverage') {
            return true;
        }
        if (selectedSnacks.salty && snack.snackType === 'salty') {
            return true;
        }
        if (selectedSnacks.sweets && snack.snackType === 'sweet') {
            return true;
        }
        return false;
    });

    // Define the validation function
    const validateForm = () => {
        const errors = {
            snackType: '',
            productBrand: '',
            productName: '',
            image: '',
        };

        if (formValues.snackType === '') {
            errors.snackType = t('snack_type_error');
        }
        if (formValues.productBrand.trim() === '') {
            errors.productBrand = t('snack_brand_error');
        }
        if (formValues.productName.trim() === '') {
            errors.productName = t('snack_name_error');
        }
        if (!formValues.image) {
            errors.image = t('snack_image_error'); // Add a translation key for the image error
        }

        setFormErrors(errors);

        return Object.values(errors).every((error) => error === '');
    };

    // Modify the handleSubmit function to use the validation function
    const handleSubmit = async (event) => {
        event.preventDefault();
        setNewItemLoading(true);

        // Call the validateForm function and stop if validation fails
        if (!validateForm()) {
            setNewItemLoading(false);
            return;
        }

        try {
            // Create a new FormData object
            const data = new FormData(event.currentTarget);

            // Append the compressed image data if it exists
            if (formValues.image) {
                data.set('image', formValues.image, formValues.image.name);
            }
            const token = sessionStorage.getItem('token');

            const response = await fetch(`${apiUrl}/newitem`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
                body: data,
            });
            if (response.ok) {
                // Close the dialog
                setOpenDialog(false);
                setNewItemLoading(false);
                setFormValues(initialFormValues);
                setFormSubmitSuccess(true);
                fetchSnacks();
            } else if (response.status === 400) {
                setFormSubmitFailure(true);
            } else {
                console.error('Failed to add item');
            }

        } catch (error) {
            console.error('Error adding item:', error);
        } finally {
            setNewItemLoading(false); // Set loading to false regardless of success/error
        }
    };


    // Comment out delete functionality for now. 16.04.2023.
    // const handleDeleteButtonClick = () => {
    //     setDeleteDialogOpen(true);
    // };

    // const handleDeleteConfDialogClose = () => {
    //     setDeleteDialogOpen(false);
    // };

    // Comment out delete functionality for now. 16.04.2023.
    // const grabCardInfo = (information) => {
    //     setCardInformation(information);
    //     handleDeleteButtonClick();
    // }

    // Comment out delete functionality for now. 16.04.2023.
    // const handleDelete = async () => {
    //     const data = cardInformation;
    //     setNewItemLoading(true);
    //     try {
    //         const token = sessionStorage.getItem('token');

    //         const response = await fetch(`${apiUrl}/deleteitem`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-type': 'application/json',
    //                 Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    //             },
    //             body: JSON.stringify(data)
    //         });

    //         if (response.ok) {
    //             fetchSnacks();
    //         } else {
    //             console.error('Failed to add item');
    //         }
    //     } catch (error) {
    //         console.error('Error adding item:', error);
    //     } finally {
    //         handleDeleteConfDialogClose();
    //         setNewItemLoading(false); // Set loading to false regardless of success/error
    //     }
    // };

    return (
        <div>
            <NavigationBar navigationBarData={dataToNavigationbar} />
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <div className='main-content'>
                    <CssBaseline />
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            {snacksNotFound ? (
                                <Alert variant='filled' severity="info" sx={{ width: '100%', marginTop: '1rem' }}>
                                    {t('snacks_not_found_info')}
                                </Alert>
                            ) : (
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {filteredSnacks
                                        .sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated))
                                        .map((snack, index) => (
                                            <Grid item key={index} xs={12} sm={6} md={4}>
                                                <Card>
                                                    <CardContent sx={{ padding: 0 }}>
                                                        {snack.image &&
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ height: '300px' }}
                                                                image={snack.image} // Add image URL if available
                                                                title="Snack Image"
                                                            />
                                                        }
                                                        <Box sx={{ padding: '1rem 1rem 0 1rem', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                            <Typography fontSize={'0.7rem'} color={alpha('#000000', 0.5)} component="div">
                                                                {t('timestamp_text')} {new Date(snack.timeCreated).toLocaleDateString('en-GB')}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    display: "-webkit-box",
                                                                    WebkitLineClamp: "1",
                                                                    WebkitBoxOrient: "vertical",
                                                                }}
                                                            >
                                                                {snack.productBrand}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    display: "-webkit-box",
                                                                    WebkitLineClamp: "1",
                                                                    WebkitBoxOrient: "vertical",
                                                                    lineHeight: 1.2,
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {snack.productName}
                                                            </Typography>
                                                            {/* Spacer to push content to the bottom */}
                                                            <Box sx={{ flexGrow: 1 }} />
                                                            <Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                                    <Typography sx={{ fontSize: '0.8em', marginRight: '0.5em' }} color={alpha('#000000', 0.5)} component="div">
                                                                        {t('average_rating_info_text')}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {snack.averageRating.toFixed(1)}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ display: 'flex' }}>
                                                                    <Rating
                                                                        name={`rating-${index}`}
                                                                        value={snack.averageRating}
                                                                        precision={0.5}
                                                                        max={5}
                                                                        size='medium'
                                                                        readOnly
                                                                    />
                                                                    <Typography sx={{ flexGrow: 1, alignContent: 'center', fontSize: '0.8em', marginLeft: '0.3em' }} color={alpha('#000000', 0.5)} component="div">
                                                                        ({snack.reviewsAmount})
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                    <CardActions sx={{ padding: '1rem 1rem 1rem 1rem' }}>
                                                        <Button size="small" variant='outlined' component={Link} to={`/snackpage/${snack.id}`}>
                                                            {t('rating_card_open_button')}
                                                        </Button>
                                                        {/* Comment out delete functionality for now. 16.04.2023. */}
                                                        {/* <Button size="small" variant='outlined' onClick={() => grabCardInfo({ productName: snack.productName, timeCreated: snack.timeCreated })}>{t('rating_card_delete_button')}</Button> */}
                                                    </CardActions>
                                                    {/* Comment out delete functionality for now. 16.04.2023. */}
                                                    {/* <Dialog
                                                        open={deleteDialogOpen}
                                                        onClose={handleDeleteConfDialogClose}
                                                    >
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                {t('delete_conf_text')}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button variant='contained' onClick={handleDeleteConfDialogClose}>{t('delete_conf_button_no')}</Button>
                                                            <Button variant='outlined' onClick={() => handleDelete()}>{t('delete_conf_button_yes')}</Button>
                                                        </DialogActions>
                                                    </Dialog> */}
                                                </Card>
                                            </Grid>
                                        ))}
                                </Grid>
                            )}
                        </div>
                    )}
                    <div style={{ marginBottom: '160px' }} />
                    <Fab onClick={handleDialogOpen} color="primary" aria-label="add" style={{ position: 'fixed', bottom: '90px', right: '20px' }}>
                        <AddOutlinedIcon />
                    </Fab>
                    <Fab onClick={handleFabDialogOpen} color="primary" aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                        <TuneIcon />
                    </Fab>
                    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={'xs'} fullWidth={true}>
                        <DialogTitle sx={{ paddingBottom: 0 }}>{t('add_new_snack_modal_header')}</DialogTitle>
                        <DialogContent>
                            <Box component="form" ref={formRef} onSubmit={handleSubmit} noValidate>
                                <FormControl fullWidth sx={{ margin: '1em 0 1em' }} error={!!formErrors.snackType}>
                                    <InputLabel id="snack-type-label">{t('snack_type_placeholder')}</InputLabel>
                                    <Select
                                        labelId="snack-type-label"
                                        id="snack-type-select"
                                        name='snackType'
                                        value={formValues.snackType}
                                        label={t('snack_type_placeholder')}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="beverage">{t('dropdown_menu_items_beverages')}</MenuItem>
                                        <MenuItem value="salty">{t('dropdown_menu_items_salty')}</MenuItem>
                                        <MenuItem value="sweet">{t('dropdown_menu_items_sweets')}</MenuItem>
                                    </Select>
                                    <FormHelperText>{formErrors.snackType}</FormHelperText>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label={t('snack_brand_placeholder')}
                                    name='productBrand'
                                    required
                                    error={!!formErrors.productBrand}
                                    value={formValues.productBrand}
                                    helperText={formErrors.productBrand}
                                    onChange={handleInputChange}
                                    sx={{ marginBottom: '1em' }}
                                />
                                <TextField
                                    fullWidth
                                    label={t('snack_name_placeholder')}
                                    name='productName'
                                    required
                                    error={!!formErrors.productName}
                                    value={formValues.productName}
                                    helperText={formErrors.productName}
                                    onChange={handleInputChange}
                                    sx={{ marginBottom: '1em' }}
                                />

                                <input
                                    ref={fileInputRef}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="file-upload"
                                    type="file"
                                    name='image'
                                    onChange={handleImageInputChange}
                                />

                                {formValues.image ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: '40px', marginBottom: '1em' }}>
                                        <Typography component='span'>{t('add_image_label')}</Typography>
                                        <label htmlFor="file-upload">
                                            <IconButton
                                                component="span"
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '8px',
                                                    width: '40px',
                                                    height: '40px',
                                                    marginLeft: '0.5rem',
                                                    backgroundColor: '#f5f5f5',
                                                }}
                                            >
                                                <TaskAltTwoToneIcon sx={{ color: green[500] }} />
                                            </IconButton>
                                        </label>
                                    </Box>
                                ) : (
                                    <FormControl fullWidth sx={{ margin: '0 0 1em' }} error={!!formErrors.image}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                                            <Typography component='span'>{t('add_image_label')}</Typography>
                                            <label htmlFor="file-upload">
                                                <IconButton
                                                    component="span"
                                                    sx={{
                                                        borderRadius: '8px',
                                                        width: '40px',
                                                        height: '40px',
                                                        marginLeft: '0.5rem',
                                                        backgroundColor: '#f5f5f5',
                                                        '&:hover': {
                                                            backgroundColor: '#e0e0e0',
                                                        },
                                                    }}
                                                >
                                                    <AttachFileIcon />
                                                </IconButton>
                                            </label>
                                        </Box>
                                        <FormHelperText>{formErrors.image}</FormHelperText>
                                    </FormControl>
                                )}
                                <Typography id="rating-slider" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                                    {t('rating_text')}
                                    <Rating
                                        value={formValues.rating}
                                        precision={0.5}
                                        max={5}
                                    />
                                </Typography>
                                <Slider
                                    name='rating'
                                    min={1}
                                    max={5}
                                    step={0.5}
                                    aria-label="rating-slider"
                                    sx={{ my: 1 }}
                                    defaultValue={3}
                                    onChange={handleSliderChange}
                                />
                                <TextareaAutosize
                                    placeholder={t('add_comment_placeholder')}
                                    name='comment'
                                    minRows={6}
                                    style={{ width: '100%' }}
                                />
                                <DialogActions>
                                    <Button onClick={handleDialogClose}>{t('cancel_button')}</Button>
                                    <Button type='submit' variant="contained" autoFocus>
                                        {t('save_button')}
                                    </Button>
                                </DialogActions>
                                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={newItemLoading}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openFabDialog} onClose={handleFabDialogClose}>
                        <DialogTitle>{t('filter_results_modal_header')}</DialogTitle>
                        <DialogContent>
                            <FormControlLabel
                                control={<Checkbox checked={selectedSnacks.beverages} onChange={handleCheckboxChange} name="beverages" />}
                                label={t('filter_results_modal_checkbox_beverages')}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedSnacks.salty} onChange={handleCheckboxChange} name="salty" />}
                                label={t('filter_results_modal_checkbox_salty')}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedSnacks.sweets} onChange={handleCheckboxChange} name="sweets" />}
                                label={t('filter_results_modal_checkbox_sweets')}
                            />
                        </DialogContent>
                    </Dialog>
                    <Snackbar open={formSubmitFailure} autoHideDuration={3000} onClose={() => setFormSubmitFailure(false)}>
                        <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
                            {t('field_empty_server_error')}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={formSubmitSuccess} autoHideDuration={3000} onClose={() => setFormSubmitSuccess(false)}>
                        <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
                            {t('snack_added_success')}
                        </Alert>
                    </Snackbar>
                    {/* <Snackbar open={snacksNotFound} autoHideDuration={6000} onClose={() => setSnacksNotFound(false)}>
                        <Alert variant='filled' severity="error" sx={{ width: '100%' }}>
                            {t('login_failure_alert')}
                        </Alert>
                    </Snackbar> */}
                </div>
            </div>
        </div >
    );
}
export default MySnacks;